import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import 'antd/dist/antd.css';
import axios from 'axios';
import './App.scss';
import ManagersContainer from './ManagersContainer';
import AddGuideContainer from './AddGuideContainer';
import GuideScreenContainer from './GuideScreenContainer';
import GuideManageTasks from './GuideManageTasks.container';
import GuideManageBonuses from './GuideManageBonuses.container';
import AddStudent from './AddStudentContainer';
import ManageStudent from './ManageStudentContainer';
import LoginPage from './LoginPage';
import HomePage from './HomePageContainer';
import { UserProvider } from '../contexts/userContext';
import ParashaContainer from './ParashaContainer';
import UserProfileContainer from './UserProfileContainer';
import MissionContainer from './MissionContainer';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const userRes = await axios.get('/api/logged-user');
        setUser(userRes.data);
      } catch (error) {
        if (window.location.pathname !== '/login') window.location.assign('/login');
      }
    };
    getUser();
  }, []);

  return (
    <UserProvider value={user}>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/admin/manager/add-guide">
              <AddGuideContainer />
            </Route>
            <Route path="/admin/manager/edit-guide/:guideId">
              <AddGuideContainer />
            </Route>
            <Route path="/admin/add-student">
              <AddStudent />
            </Route>
            <Route path="/admin/edit-student/:studentId">
              <AddStudent />
            </Route>
            <Route path="/admin/manage-student/:studentId">
              <ManageStudent />
            </Route>
            <Route path="/admin/manager">
              <ManagersContainer />
            </Route>
            <Route path="/admin/guide">
              <GuideScreenContainer />
            </Route>
            <Route path="/guide/manage-tasks">
              <GuideManageTasks />
            </Route>
            <Route path="/guide/manage-bonuses">
              <GuideManageBonuses />
            </Route>
            <Route path="/user/profile">
              <UserProfileContainer />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/haftara">
              <ParashaContainer haftara />
            </Route>
            <Route path="/parasha">
              <ParashaContainer />
            </Route>
            <Route path="/missions/:missionId">
              <MissionContainer />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;

import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import moment from 'moment';
import qs from 'qs';
import { DatePicker, Input as AntdInput } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import './AddStudentContainer.scss';
import axios from 'axios';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Page from './Page';
import FooterWithButton from '../components/footerWithButton';
import SelectField from '../components/forms/selectField';
import UserContext from '../contexts/userContext';
import statusEnum from '../enums/status.enum';
import { getHebBirthDate } from '../utils/dates';
import Button from '../foundation/Button';
import Input from '../foundation/Input';

const { TextArea } = AntdInput;

const selectActiveStatusOpts = [
  { value: statusEnum.active, text: 'פעיל' },
  { value: statusEnum.notActive, text: 'לא פעיל' },
];

const defaultValues = {
  status: statusEnum.active,
};

function AddStudent() {
  // support edit mode
  const { studentId } = useParams();
  const history = useHistory();
  const user = useContext(UserContext);
  const [student, setStudent] = useState(defaultValues);
  const [parashot, setParashot] = useState([]);
  const [nusach, setNusach] = useState(0);
  const [error, setError] = useState(null);

  // in order to check if the user state changed from active to non active
  const studentOrigActive = useRef(null);

  function validate() {
    let err;
    if (!student) {
      err = 'יש להזין שם תלמיד';
      setError(err);
      return false;
    }

    if (!student.name) err = 'יש להזין שם תלמיד';
    else if (!student.lastName) err = 'יש להזין שם משפחה';
    // if (!student.motherName) err = 'יש להזין את שם האם';
    // if (!student.fatherName) err = 'יש להזין את שם האב';
    else if (!student.parentPhone) err = 'יש להזין טלפון של ההורה';
    else if (!student.parasha1) err = 'יש לבחור פרשה ראשונה';
    // if (!student.parasha2) err = 'יש לבחור פרשה שנייה';
    else if (!student.birthDate) err = 'יש לבחור תאריך לידה';
    else if ((Date.now() - (new Date(student.birthDate)).getTime()) / (1000 * 60 * 60 * 24 * 365) < 11) err = 'התלמיד מתחת לגיל 11';
    // if (!student.haftara) err = 'יש לבחור הפטרה';
    else if (!student.phoneNumber) err = 'יש להזין טלפון';

    // create fields validation
    else if (!studentId) {
      if (!student.password || !student.passwordConfirm) err = 'יש להזין סיסמא';
      else if (student.password !== student.passwordConfirm) err = 'הסיסמאות אינן זהות';
    }

    if (err) {
      setError(err);
      return false;
    }
    return true;
  }

  const isMounted = useRef(true);

  useEffect(() => () => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getStudent = async () => {
      const studentRes = await axios.get(`/api/users/${studentId}?deleted=true`);
      setStudent(studentRes.data);
      studentOrigActive.current = studentRes.data.status === statusEnum.active;
      setNusach(parseInt(studentRes.data.nusach, 10));
    };
    if (studentId) getStudent();
  }, [studentId]);

  useEffect(() => {
    const getParashot = async () => {
      const parsahotRes = await axios.get('/api/parashot');
      setParashot(parsahotRes.data.map((p) => ({
        text: p.name,
        value: p.parashaId,
        bookId: p.bookId,
      })));
    };
    getParashot();
  }, []);

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  async function handleAddClick() {
    setSubmitDisabled(true);
    if (!validate()) return;
    const updateUser = async () => {
      const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
      const queryGuideId = queryString?.guideId;

      try {
        await axios.post('/api/users', {
          ...student,
          guideId: user.isGuide ? user.userId : (queryGuideId || student.guideId),
          isStudent: true,
          nusach,
          status: student.status ?? statusEnum.notActive,
        });
        if (history.location.search || user.isGuide) history.push(`/admin/guide${history.location.search}`);
        else history.push('/admin/manager/students');
      } catch (err) {
        if (err.response.status === 409) setError('משתמש כבר קיים. בחר מספר טלפון אחר.');
        else setError('אירעה שגיאה');
      }
    };
    await updateUser();

    if (isMounted) {
      setSubmitDisabled(false);
    }
  }

  function handleChange(event) {
    if (event.target.name === 'status') {
      setStudent({
        ...student,
        status: selectActiveStatusOpts[event.target.selectedIndex - 1].value,
      });
    } else {
      setStudent({
        ...student,
        [event.target.name]: event.target.value,
      });
    }
  }

  function renderDate() {
    if (!student?.birthDate) return '';
    return (
      <span className="hebrew-date">
        תאריך עברי:
        {' '}
        {getHebBirthDate(student.birthDate, student.afterSunset)}
      </span>
    );
  }
  if (studentId && !student) return '';

  // const disableMandatoryFields = studentId && !user?.isManager
  //   ? student.activationDate || student.status === statusEnum.active : false;

  const disableMandatoryFields = false;

  return (
    <Page title={studentId ? 'עריכת תלמיד' : 'הוספת תלמיד'} showBackBtn>
      <div className="add-student-container">
        <div className="add-guide-form">
          <br />
          <div className="stars-wrapper">
            <SelectField
              text="סטאטוס"
              disabled={disableMandatoryFields}
              options={selectActiveStatusOpts}
              name="status"
              defaultValue={(student ? (student.status ?? statusEnum.notActive)
                : defaultValues.status).toString()}
              placeholder="סטטוס"
              onChange={handleChange}
            />
            <Input
              className="input-field"
              name="stars"
              type="number"
              min={0}
              max={999}
              autoComplete="off"
              onChange={handleChange}
              placeholder="כוכבים"
              value={student?.stars}
            />
            <div>
              <Button
                onClick={(ev) => setStudent({
                  ...student,
                  stars: (parseInt(student?.stars, 10) || 0) + 5,
                })}
                square
                small
              >
                <span>+5</span>

              </Button>
              <Button
                onClick={(ev) => setStudent({
                  ...student,
                  stars: (parseInt(student?.stars, 10) || 0) + 10,
                })}
                square
                small
              >
                <span>+10</span>

              </Button>
              <Button
                onClick={(ev) => setStudent({
                  ...student,
                  stars: (parseInt(student?.stars, 10) || 0) + 20,
                })}
                square
                small
              >
                <span>+20</span>

              </Button>
            </div>
          </div>
          <div>
            <Input
              className="input-field"
              name="name"
              autoComplete="off"
              onChange={handleChange}
              placeholder="שם פרטי"
              disabled={disableMandatoryFields}
              value={student?.name}
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="lastName"
              autoComplete="off"
              onChange={handleChange}
              value={student?.lastName}
              disabled={disableMandatoryFields}
              placeholder="שם משפחה"
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="motherName"
              autoComplete="off"
              onChange={handleChange}
              value={student?.motherName}
              placeholder="שם האם"
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="fatherName"
              autoComplete="off"
              onChange={handleChange}
              value={student?.fatherName}
              placeholder="שם האב"
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="parentPhone"
              autoComplete="off"
              value={student?.parentPhone}
              onChange={handleChange}
              placeholder="טלפון של הורה"
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="phoneNumber"
              autoComplete="off"
              value={student?.phoneNumber}
              onChange={handleChange}
              placeholder="טלפון של הילד"
              disabled={disableMandatoryFields}
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="address"
              autoComplete="off"
              value={student?.address}
              onChange={handleChange}
              placeholder="כתובת מגורים"
              disabled={disableMandatoryFields}
            />
          </div>
          <>
            <div>
              <Input
                className="input-field"
                name="password"
                type="password"
                onChange={handleChange}
                placeholder="סיסמא"
                autoComplete="new-password"
              />
            </div>
            <div>
              <Input
                className="input-field"
                name="passwordConfirm"
                type="password"
                autoComplete="new-password"
                onChange={handleChange}
                placeholder="אימות סיסמא"
              />
            </div>
          </>
          <SelectField
            text="פרשה"
            options={parashot}
            name="parasha1"
            value={parashot?.find((i) => i.value === parseInt(student?.parasha1, 10))?.value}
            placeholder="בחר פרשה ראשונה"
            disabled={disableMandatoryFields}
            onChange={(ev) => {
              setStudent({
                ...student,
                [ev.target.name]: parashot[ev.target.selectedIndex - 1].value,
              });
            }}
          />
          <br />
          <SelectField
            text="פרשה"
            options={parashot}
            name="parasha2"
            value={parashot?.find((i) => i.value === parseInt(student?.parasha2, 10))?.value}
            placeholder="בחר פרשה שנייה"
            disabled={disableMandatoryFields}
            onChange={(ev) => setStudent({
              ...student,
              [ev.target.name]: parashot[ev.target.selectedIndex - 1].value,
            })}
          />
          <br />
          <div>
            <SelectField
              text="הפטרה"
              options={parashot.filter((p) => p.bookId <= 5)}
              name="haftara"
              value={parashot?.find((i) => i.value === parseInt(student?.haftara, 10))?.value}
              placeholder="בחר הפטרה"
              onChange={(ev) => setStudent({
                ...student,
                [ev.target.name]: parashot[ev.target.selectedIndex - 1].value,
              })}
            />
          </div>
          <br />
          <div className="select-buttons-wrapper">
            <div className="nusah-lbl"><span>נוסח הקריאה</span></div>
            <div className="select-buttons">
              <button type="button" onClick={() => setNusach(0)} className={`select-button${nusach === 0 ? ' selected' : ''}`}>
                <span>ספרדי מרוקאי</span>
              </button>
              <button type="button" onClick={() => setNusach(1)} className={`select-button${nusach === 1 ? ' selected' : ''}`}>
                <span>אשכנזי</span>
              </button>
              <button type="button" onClick={() => setNusach(2)} className={`select-button${nusach === 2 ? ' selected' : ''}`}>
                <span>ספרדי ירושלמי</span>
              </button>
            </div>
          </div>
          <br />
          <div className="birth-date">
            <div className="nusah-lbl">בחר תאריך לידה</div>
            <DatePicker
              name="birthDate"
              disabled={disableMandatoryFields}
              allowClear={false}
              className="date-picker"
              label="בחר תאריך לידה"
              placeholder="תאריך לידה"
              value={moment(student?.birthDate)}
              onChange={(date) => setStudent({ ...student, birthDate: date })}
            />
            <Checkbox
              checked={student?.afterSunset}
              name="afterSunset"
              onChange={(ev) => setStudent({ ...student, afterSunset: ev.target.checked })}
            >
              אחרי השקיעה
            </Checkbox>
            <br />
            {renderDate()}
          </div>
          <br />
          <div className="textarea-field">
            <TextArea
              value={student?.comment}
              onChange={handleChange}
              rows={4}
              placeholder="הערה"
              name="comment"
            />
          </div>
          <br />
        </div>
        <br />
        <FooterWithButton disabled={submitDisabled} btnText={studentId ? 'שמור' : 'הוסף'} btnClick={handleAddClick}>
          {error && (
            <div className="form-error">
              <span>{error}</span>
            </div>
          )}
        </FooterWithButton>
      </div>
    </Page>

  );
}

export default AddStudent;
